/* ------------------------------------------------
  Project:   Softino - Software Landing Page HTML5 Template
  Author:    ThemeHt
------------------------------------------------ */
/* ------------------------
    Table of Contents

  1. General
  2. Typography
  3. Helper class
  4. Text color
  5. Background color
  6. Border Radius
  7. Banner
  8. Background Position & Size
  9. Countdown
  10. Terms and Conditions
  11. Privacy Policy
  12. Clients Logo
  13. Contact
  14. Video
  15. Image Box
  16. Side Background
  17. Error Page
  18. List Icon
  19. Bg Animated Image
  20. How It Work
  21. Spinner
  22. Hexagon
  23. Timeline
  24. Other


/* ------------------------
    General
------------------------*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,800,900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  text-align: right;
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
  color: #5f5f5f;
  letter-spacing: 1px;
  direction: rtl;
}

/* ------------------------
    Typography
------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-family: "Nunito", sans-serif;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 50px;
  font-style: normal;
  line-height: 70px;
  font-weight: bold;
  text-transform: capitalize;
  color: #1c1d3e;
  letter-spacing: 0;
}
h2 {
  font-size: 40px;
  font-style: normal;
  line-height: 46px;
  font-weight: 600;
  color: #592e7a;
  text-transform: capitalize;
  margin-bottom: 10px;
  position: relative;
}
h3 {
  font-size: 30px;
  font-style: normal;
  line-height: 36px;
  font-weight: 500;
}
h4 {
  font-size: 24px;
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 30px;
  color: #1c1d3e;
}
h5 {
  font-size: 20px;
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 26px;
  color: #1c1d3e;
}
h6 {
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 10px;
  color: #1c1d3e;
}
ul {
  margin: 0px;
  padding: 0px;
}
u {
  text-decoration-style: wavy;
}

body.home-2 h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}
body.home-3 h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}
body.home-4 h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}
body.home-5 h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}
body.home-6 h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

/* ------------------------
    Helper class
------------------------*/
p {
  line-height: 1.7;
}
.lead {
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}
.o-hidden {
  overflow: hidden;
}
.o-x-hidden {
  overflow-x: hidden;
}
.img-center {
  display: inline-block !important;
  max-width: 100%;
}
.pos-r {
  position: relative;
}
.z-index-0 {
  z-index: 0;
  position: relative;
}
.z-index-1 {
  z-index: 1;
  position: relative;
}
h1 span {
  color: #692b7c;
}
section {
  padding: 80px 0;
}
.section-title {
  margin-bottom: 50px;
  position: relative;
}
.section-title p {
  font-size: 16px;
}
.align-item-middle {
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  align-items: center;
}
.align-item-middle > div {
  width: 100%;
}
.la {
  line-height: inherit;
}

.font-w-3 {
  font-weight: 300;
}
.font-w-4 {
  font-weight: 400;
}
.font-w-5 {
  font-weight: 500;
}
.font-w-6 {
  font-weight: 600;
}
.font-w-7 {
  font-weight: 700;
}
.font-w-8 {
  font-weight: 800;
}
.font-w-9 {
  font-weight: 900;
}

.line-h-2 {
  line-height: 1.6;
}
.line-h-3 {
  line-height: 1.7;
}
.line-h-4 {
  line-height: 1.8;
}
.line-h-5 {
  line-height: 1.9;
}

.letter-space-1 {
  letter-spacing: 5px;
}
.letter-space-2 {
  letter-spacing: 10px;
}
.letter-space-3 {
  letter-spacing: 15px;
}

/* ------------------------
    Text color
------------------------*/
.text-theme {
  color: #692b7c;
}
.text-black {
  color: #1c1d3e !important;
}
.text-grey {
  color: #999999;
}
.text-rgba {
  color: rgba(255, 255, 255, 0.8);
}

/* ------------------------
    Background color
------------------------*/
.white-bg {
  background-color: #ffffff !important;
}
.dark-bg {
  background-color: #02060b;
}
.grey-bg {
  background-color: #f3f5ff;
}
.theme-bg {
  background-color: #692b7c;
}
.theme2-bg {
  background-color: #eae4ef;
}
.transparent-bg {
  background-color: inherit !important;
}
.grediant-bg {
  background-color: rgb(0, 91, 234);
  background-color: linear-gradient(
    90deg,
    rgba(0, 91, 234, 1) 0%,
    rgba(37, 117, 252, 1) 80%
  );
}

/* ------------------------
    Border Radius
------------------------*/
.radius {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.radius-0 {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.radius-1 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.radius-2 {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.radius-3 {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.radius-4 {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* ------------------------
    Banner
------------------------*/
.fullscreen-banner {
  position: relative;
}
[data-bg-img] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.align-center {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 9;
}
.align-end {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 9;
}
.fullscreen-banner .owl-carousel,
.fullscreen-banner .owl-stage-outer,
.fullscreen-banner .owl-stage,
.fullscreen-banner .owl-item {
  height: 100%;
}
.hero-bg {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.fullscreen-banner .img-side {
  top: 50%;
  transform: translateY(-50%);
  right: -5%;
  height: auto;
}

.banner h5 {
  background: #ffb58c;
  text-transform: uppercase;
  padding: 7px 15px;
  display: inline-block;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.home-6 .banner .bnr-box {
  background: #5c2ec2;
}

/* -------------------------------
   Background Position & Size
-------------------------------*/
.bg-cover {
  background-size: cover;
}
.bg-contain {
  background-size: contain;
}
.bg-pos-l {
  background-position: left;
}
.bg-pos-r {
  background-position: right;
}
.bg-pos-rt {
  background-position: right top;
}
.bg-pos-lt {
  background-position: left top;
}
.bg-pos-rb {
  background-position: right bottom;
}
.bg-pos-lb {
  background-position: left bottom;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  position: relative !important;
  width: 100% !important;
}

/* ------------------------
    Countdown
------------------------*/
.countdown {
  margin: 50px 0;
  padding: 50px 0;
  display: table;
  width: 100%;
  border-top: 2px dashed rgba(255, 255, 255, 0.5);
  border-bottom: 2px dashed rgba(255, 255, 255, 0.5);
}
.countdown > li {
  font-weight: 700;
  text-align: center;
  list-style-type: none;
  display: table-cell;
  width: auto;
}
.countdown > li span {
  font-size: 70px;
  font-weight: 700;
  line-height: 60px;
  display: inline-block;
}
.countdown > li p {
  font-size: 20px;
  display: inline-block;
  line-height: 30px;
  margin-bottom: 0;
  color: #1c1d3e;
}
.coming-soon .subscribe-form {
  margin: 0 auto;
}

/* ------------------------
    Terms and Conditions
------------------------*/
.terms-and-conditions p {
  margin-bottom: 25px;
}
.terms-and-conditions ul.list {
  margin-bottom: 20px;
  display: block;
}
.terms-and-conditions ul.list li i {
  padding-right: 12px;
  font-size: 15px;
}

/* ------------------------
    Privacy Policy
------------------------*/
.privacy-policy p {
  margin-bottom: 25px;
}
.privacy-policy ul.list {
  margin-bottom: 20px;
  display: block;
}
.privacy-policy ul.list li i {
  padding-right: 12px;
  font-size: 15px;
}

/* ------------------------
    Clients Logo
------------------------*/
.clients-logo {
  background: #fff;
  padding: 50px 10px;
  width: 100%;
  text-align: center;
  border-radius: 7px;
}

/* ------------------------
    Contact
------------------------*/
.contact-media {
  background: #ffffff;
  text-align: center;
  padding: 50px 30px;
  border-radius: 7px;
  border-bottom: 3px solid #692b7c;
}
[class*="col-"]:nth-child(2) .contact-media {
  border-bottom: 3px solid #692b7c;
}
.contact-media i {
  line-height: 70px;
  font-size: 70px;
  color: #692b7c;
  margin-bottom: 30px;
  display: inline-block;
}
.contact-media span {
  font-size: 16px;
  color: #1c1d3e;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  margin-bottom: 15px;
}
.contact-media p {
  margin-bottom: 0;
  line-height: 24px;
}
.contact-media a {
  color: #1c1d3e;
}
.contact-media a:hover {
  color: #692b7c;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}
label {
  font-size: 14px;
  color: #1c1d3e;
}
.form-control {
  height: 50px;
  border-radius: 5px;
  font-size: 13px;
  color: #1c1d3e;
  background: #ded6ea;
  border: 1px solid transparent;
}
.theme-bg .form-control .dark-bg .form-control {
  background: none;
  border: 5px solid rgba(255, 255, 255, 0.1);
}
.dark-bg .form-control,
.theme-bg .form-control {
  color: #ffffff;
}
.grey-bg .form-control {
  background: #ffffff;
}
textarea.form-control {
  height: 150px;
  border-radius: 5px;
}
.form-control:focus {
  box-shadow: none;
  background: none;
  border-color: #692b7c;
}
.theme-bg .form-control:focus {
  border-color: #692b7c;
}
select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 50px !important;
}
#map {
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  border: none;
  pointer-events: none;
}
.iframe-h {
  height: 500px !important;
}
.iframe-h-2 {
  height: 600px !important;
}
.help-block.with-errors {
  position: absolute;
  color: red;
  right: 15px;
  font-size: 10px;
  line-height: 14px;
}

.form-control::-webkit-input-placeholder {
  color: #555;
}
.form-control::-moz-placeholder {
  color: #555;
}
.form-control:-ms-input-placeholder {
  color: #555;
}
.form-control:-moz-placeholder {
  color: #555;
}
.dark-bg .form-control::-webkit-input-placeholder,
.theme-bg .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.dark-bg .form-control::-moz-placeholder,
.theme-bg .form-control::-moz-placeholder {
  color: #ffffff;
}
.dark-bg .form-control:-ms-input-placeholder,
.theme-bg .form-control:-ms-input-placeholder {
  color: #ffffff;
}
.dark-bg .form-control:-moz-placeholder,
.theme-bg .form-control:-moz-placeholder {
  color: #ffffff;
}

/* ------------------------
    Video
------------------------*/
.video-btn {
  position: relative;
  display: inline-block;
}
.video-btn .play-btn {
  font-size: 30px;
  background: #692b7c;
  color: #fff;
  line-height: 50px;
  width: 70px;
  height: 70px;
  border: 10px solid rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  z-index: 9;
  position: relative;
  display: inline-block;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  -webkit-transition-property: color, background, border-color;
  transition-property: color, background, border-color;
}
.video-btn-pos {
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto !important;
  z-index: 99;
}
.theme-bg .video-btn .play-btn,
.dark-bg .video-btn .play-btn {
  background: #ffffff;
  border-color: #ddd;
  color: #692b7c;
}
.html5-video {
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}
.html5-video video {
  width: 100%;
}
.video-btn .spinner-eff {
  left: 50%;
  top: 50%;
}

/* ------------------------
    Image Box
------------------------*/
.img-box {
  background: #ffffff;
  padding-top: 50px;
  overflow: hidden;
  position: relative;
  border-radius: 7px;
}
.img-box img {
  width: 100%;
}
.box-loader {
  border-radius: 100%;
  margin: 0 auto;
  position: absolute;
  top: 15px;
  left: 15px;
}
.box-loader span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #692b7c;
  margin: 0 5px;
  opacity: 0;
}
.box-loader span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}
.box-loader span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}
.box-loader span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

/* ------------------------
    Side Background
------------------------*/
.img-side img {
  max-width: 100%;
}
.img-side.img-right {
  right: 0;
}
.img-side.img-left {
  left: 0;
}
.img-side {
  padding: 0;
  position: absolute !important;
  top: 0px;
  height: 100%;
  overflow: hidden;
  z-index: 9;
}
.image-column {
  position: absolute;
  right: 0px;
  top: 50%;
}
.image-column.left {
  left: 0;
  right: inherit;
}

/* ------------------------
    Error Page
------------------------*/
.error-page {
  font-size: 350px;
  font-weight: bold;
  line-height: 250px;
  font-family: "Montserrat", sans-serif;
}
.error-page > span {
  display: inline-block;
  position: relative;
  color: #692b7c;
}
.error-page > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.error-page > span:nth-of-type(1) {
  color: #692b7c;
  animation: colordancing 4s infinite;
}
.error-page > span:nth-of-type(3) {
  color: #00c9ff;
  animation: colordancing2 4s infinite;
}
.error-page > span:nth-of-type(2) {
  width: 250px;
  height: 120px;
  border-radius: 999px;
}
.error-page > span:nth-of-type(2):before,
.error-page > span:nth-of-type(2):after {
  border-radius: 0%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(0, 201, 255, 0.4),
    inset 0 30px 0 rgba(5, 248, 161, 0.4), inset -30px 0 0 rgba(255, 8, 68, 0.4),
    inset 0 -30px 0 rgba(255, 81, 47, 0.4);
  animation: shadowsdancing 4s infinite;
}
.error-page > span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

/* ------------------------
    List Icon
------------------------*/
.list-icon li {
  position: relative;
  padding-right: 30px;
}
.list-icon li i {
  position: absolute;
  right: 0;
  top: 0;
  color: #692b7c;
  font-size: 22px;
  line-height: 20px;
}
.list-icon-2 li {
  padding-right: 20px;
  position: relative;
}
.list-icon-2 li:before {
  content: "";
  background: #692b7c;
  position: absolute;
  top: 8px;
  right: 0;
  width: 10px;
  height: 2px;
  border-radius: 4px;
}
.custom-li {
  display: inline-block;
}
.custom-li li {
  float: left;
  margin-bottom: 15px;
  width: calc(100% / 2);
}

/* ------------------------
    Bg Animated Image
------------------------*/
.info-img:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 330px;
  width: 100%;
  content: "";
  z-index: -1;
}
.bg-effect::after {
  background: #fafaff;
  top: 0;
  content: "";
  right: 25%;
  position: absolute;
  right: 0;
  z-index: -1;
  height: 100%;
  border-top-right-radius: 50%;
}
.bg-effect.right::after {
  right: 0;
  left: 25%;
  border-top-right-radius: 0;
  border-top-left-radius: 50%;
}
.bg-effect-2::before {
  background: #fff;
  top: -50px;
  content: "";
  height: 120px;
  left: 0;
  overflow: visible;
  position: absolute;
  transform: skewY(4deg);
  width: 100%;
}
.bg-effect-3::after {
  background: #fff;
  bottom: -50px;
  content: "";
  height: 120px;
  left: 0;
  overflow: visible;
  position: absolute;
  transform: skewY(-4deg);
  width: 100%;
}
.bg-effect-4::after {
  background: #692b7c;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  content: "";
  height: 80%;
  left: -20%;
  position: absolute;
  top: 10%;
  transform: rotate(-5deg);
  width: 70%;
  z-index: -1;
}
.bg-animation {
  position: absolute;
  top: -100px;
  left: 0;
}
.wave-shape {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
}
.wave-bg > use {
  animation: move-forever 20s linear infinite;
}
.wave-bg > use:nth-child(1) {
  animation-delay: -2s;
}
.wave-bg > use:nth-child(2) {
  animation-delay: -2s;
  animation-duration: 10s;
}
.wave-bg > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 8s;
}
.wave-animation {
  display: block;
  width: 100%;
  height: 8em;
  max-height: 100vh;
  margin: 0;
  position: absolute;
  bottom: -40px;
}

/* ------------------------
    How It Work
------------------------*/
.work-process {
  position: relative;
}
.step-num {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 12px 20px 0 rgba(89, 46, 122, 0.3);
  color: #ff0066;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  right: 0;
  line-height: 55px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 50px;
}
.theme-bg .work-process h4,
.theme-bg .work-process p {
  color: #ffffff;
}
.step-icon {
  background: #ffffff;
  width: 140px;
  height: 140px;
  position: relative;
  border: 3px solid #fafaff;
  border-radius: 50%;
  line-height: 140px;
  font-size: 40px;
  text-align: center;
  color: #1c1d3e;
}
.step-icon span {
  position: relative;
}
.step-icon span::after,
.step-icon span::before {
  border-radius: 50%;
  content: "";
  height: 40px;
  position: absolute;
  width: 40px;
  z-index: -1;
}
.step-icon span::after {
  background: #cdf3f6;
  height: 25px;
  left: -10px;
  top: 5px;
  width: 25px;
}
.step-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}
.dark-bg .step-icon {
  color: rgba(255, 255, 255, 0.9);
  background: none;
}
.dark-bg .step-desc h4 {
  color: #692b7c;
}
.dark-bg .step-icon {
  border-color: rgba(255, 255, 255, 0.1);
}
.dark-bg .step-desc p {
  color: rgba(255, 255, 255, 0.7);
}
.step-num-box {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}
.work-process .box-loader {
  position: absolute;
  top: 30%;
  left: -15%;
  right: inherit;
}
.work-process.style-2 {
  padding: 0;
  display: flex;
  align-items: center;
}
.work-process.style-2:before {
  display: none;
}
.work-process.style-2 .step-num-box {
  margin-bottom: 0;
  margin-left: 30px;
}
.work-process.style-3 {
  padding: 70px 30px 0;
}
.work-process.style-3 .step-num {
  background: none;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.1);
  font-size: 120px;
  height: auto;
  left: 50%;
  line-height: 120px;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: auto;
}
#svg-container {
  position: absolute;
  width: 100%;
  left: 52%;
  z-index: -1;
  transform: translateX(-50%);
  width: 75%;
}
#svgC {
  margin: 0 auto;
  width: 100%;
}

/* ------------------------
    Spinner
------------------------*/
.spinner-eff {
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 0;
}
.spinner-eff .spinner-circle {
  border-radius: 100px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transform: scale(1);
  transform-origin: center center;
}
.spinner-eff .circle-1 {
  width: 100%;
  height: 100%;
  background-color: #60f691;
  top: 0;
  animation: pulse 1.6s linear 0s infinite;
}
.spinner-eff .circle-2 {
  width: 66.6%;
  height: 66.6%;
  background-color: #0cca4a;
  top: 16.5%;
  animation: pulse-2 1.6s linear 0s infinite;
}

/* ------------------------
    Hexagon
------------------------*/
.hexagon-bg {
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  display: flex;
}
.hexagon-bg.style-2 {
  top: 50%;
}
.hexagon {
  position: relative;
  margin: 0 auto;
  width: 10em;
  height: 17.32em;
  border-radius: 1em/0.5em;
  background: #fafaff;
  transition: opacity 0.5s;
}
.hexagon:before,
.hexagon:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
}
.hexagon:before {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.hexagon:after {
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
.hexagon:nth-child(2n + 2) {
  margin-top: 20px;
}
.hexagon-bg.style-2 .hexagon {
  background: #ff0844;
  opacity: 0.05;
}

/* ------------------------
    Timeline
------------------------*/
.timeline {
  position: relative;
  margin: 0 auto;
}
.timeline:after {
  content: "";
  position: absolute;
  width: 5px;
  background: #fafaff;
  left: 50%;
  top: 0;
  bottom: 0;
  margin-left: -2px;
}
.timeline--first:after {
  top: 20px;
}
.timeline-title {
  text-align: center;
  position: relative;
  z-index: 1;
  padding-bottom: 20px;
}
.timeline-title h2 {
  background: #ffffff;
  color: #692b7c;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 7px;
  font-size: 24px;
  line-height: 30px;
}
.timeline-inner {
  padding: 10px 30px 40px 30px;
  width: 50%;
  position: relative;
  background-color: inherit;
}
.timeline-right {
  margin-right: 50%;
}
.timeline-content {
  padding: 20px 30px;
  background: #ffffff;
  position: relative;
  border-radius: 7px;
}
.timeline-content h3 {
  font-size: 20px;
  color: #1c1d3e;
  font-weight: bold;
}
.timeline-inner::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -16px;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid #692b7c;
  border-bottom: 3px solid transparent !important;
  border-radius: 50%;
  z-index: 9;
  background: #ffffff;
  -webkit-animation: loader-3-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-3-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
.timeline-inner::after {
  content: "";
  position: absolute;
  top: 0;
  left: -3px;
  margin: auto;
  width: 6px;
  height: 6px;
  background: #005bea;
  border-radius: 50%;
  z-index: 9;
  -webkit-animation: loader-3-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-3-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
.timeline-right.timeline-inner::before {
  left: inherit;
  right: -16px;
}
.timeline-right.timeline-inner::after {
  left: inherit;
  right: -3px;
}
.timeline-content:before,
.timeline-content:after {
  content: "";
  background: #692b7c;
  border-radius: 7px;
  width: 0;
  height: 3px;
  position: absolute;
}
.timeline-content:before {
  top: 0;
  left: 0;
}
.timeline-content:after {
  bottom: 0;
  right: 0;
}
.timeline-inner:hover .timeline-content:before,
.timeline-inner:hover .timeline-content:after {
  width: 10%;
}

/* ------------------------
    Other
------------------------*/
#canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
}
#confetti {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.blink-img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
}
.morph-wrap {
  position: absolute;
  top: 0;
  right: -20%;
  bottom: 0;
  width: 70%;
  pointer-events: none;
  z-index: -1;
  transform: rotate(90deg);
}
.morph {
  position: relative;
  height: 130%;
  fill: #f3d3ed;
  flex: none;
  width: 150%;
}
.login-form img {
  height: 100px;
}
.remember-checkbox label {
  margin-bottom: 0;
}
.round-shape {
  position: absolute;
  left: 10%;
  bottom: 0;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  background: none;
  border: 50px solid #692b7c;
  animation: heartbeat 0.5s cubic-bezier(0.245, 0.325, 0.51, 1.305) infinite
    alternate;
  opacity: 0.05;
}
.left {
  text-align: left !important;
}
.col-lg-6.col-md-12.md-mt-5.order-lg-12 {
  direction: ltr !important;
  text-align: left;
}
