@import "~styles/colors.scss";

.footer {
  background-image: url("~assets/bg/03.png");
}

.scrollTop {
  display: block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 7px;
  box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  font-size: 32px;
  color: $white;
  background: $pink;
  cursor: pointer;
}
