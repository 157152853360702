@import "./variables.scss";

@media screen and (max-width: $large-width) {
  .hero-bg {
    right: 0;
  }
}

@media screen and (max-width: $small-width) {
  .hero-bg img {
    max-width: inherit;
    width: 140% !important;
  }
}
