@import "./softino/bootstrap.min.css";
@import "./softino/animate.css";
@import "./softino/fontawesome-all.css";
@import "./softino/line-awesome.min.css";
@import "./softino/magnific-popup/magnific-popup.css";
@import "./softino/owl-carousel/owl.carousel.css";
@import "./softino/base.css";
@import "./softino/shortcodes.css";
@import "./softino/style.css";
@import "./softino/responsive.css";
@import "./colors.scss";
@import "./variables.scss";
@import "./mediaQueries.scss";
@import url(https://fonts.googleapis.com/css?family=Montserrat);

body {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  text-align: right;
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
  color: #5f5f5f;
  letter-spacing: 1px;
  direction: rtl;
  background-color: $white;
}

section {
  padding-top: 90px;
  padding-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
ul,
input,
button,
.carousel {
  font-family: "Montserrat", sans-serif;
  direction: ltr;
}

iframe {
  pointer-events: inherit;
}

.App {
  overflow: hidden;
}

.footer {
  padding: 100px 0;
}

.btn {
  cursor: pointer;

  &.btn-primary,
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: $white;
    color: $brand-color;
  }

  &.btn-secondary,
  &.btn-secondary:not(:disabled):not(.disabled).active,
  &.btn-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: $brand-color;
    color: $white;
  }

  &.btn-transparent,
  &.btn-transparent:not(:disabled):not(.disabled).active,
  &.btn-transparent:not(:disabled):not(.disabled):active,
  .show > .btn-transparent.dropdown-toggle {
    background-color: $transparent;
    color: $brand-color;
    box-shadow: none;
  }
}

.contact-media {
  min-height: 300px;
}

.pointer {
  cursor: pointer;
}

.carousel {
  padding-bottom: 50px;

  .carousel-indicators {
    li {
      background-color: $brand-color;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }
  }
}

.dropdown {
  &.show {
    .dropdown-toggle::after {
      transform: rotate(-180deg);
    }
  }
  .dropdown-toggle::after {
    transition: transform 200ms;
  }
}
